<template>
  <div style="padding-bottom:100px">
    <HomePageTop/>
    <div class="serviceEvaluationPage">
      <header>
        <div class="fontSize20 color333 fontWeightBold">{{ $fanyi("配送单号") }}：{{ $route.query.porder_sn }}</div>
      </header>
      <section>
        <div class="sectionTitle">{{ $fanyi("配送单评价") }}</div>
        <div class="sectionTitleExplain">{{ $fanyi("满意度为1到5星，星级越高越满意") }}</div>
        <div class="headerEvaluateContainer">
          <overallSatisfactionOfGoods ref="overallSatisfactionOfGoods"/>
          <overallServiceSatisfaction ref="overallServiceSatisfaction"/>
          <logisticsSatisfaction ref="logisticsSatisfaction"/>
        </div>
        <div class="headerEvaluateContainer" style="margin-top:26px">
          <div class="evaluateTitle">{{ $fanyi("配送单整体评价") }}</div>
          <div class="flexAndCenterAndCenter">
            <el-input v-model="porderEvaluate" :placeholder="$fanyi('说说你对本次服务的感受')" :readonly="disabled"
                      type="textarea"
                      resize="none"/>
          </div>
          <div v-if="disabled==false" class="picUploadContainer">
            <div class="picUploadHeaderContainer flexAndCenter">
              <div>{{ $fanyi("图片上传") }}</div>
              <div>{{ $fanyi("（*单张图片不能超过10MB，最多上传4个图片）") }}</div>
            </div>
            <el-upload
                :action="uploadUrl"
                list-type="picture-card"
                :file-list="fileList"
                :limit="4"
                :on-exceed="handleExceed"
                :before-upload="beforeAvatarUpload"
                :on-success="handleSuccess"
                :on-preview="handlePictureCardPreview"
                :on-remove="handleRemove">
              <i class="el-icon-plus"></i>
            </el-upload>
          </div>
          <div v-else class="imageList">
            <img v-for="(item,index) in fileList" :key="index" :src="item" @click="pictureCardPreview(item)">
          </div>
        </div>
      </section>
      <footer v-if="disabled===false" @click="submitPorderEvaluation">
        <div>{{ $fanyi("提交评价") }}</div>
      </footer>
    </div>
    <el-dialog :visible.sync="imageDialogVisible" width="800px">
      <div style="display: flex;align-items: center;justify-content: center">
        <img :src="dialogImageUrl" alt="" width="600px">
      </div>
    </el-dialog>
  </div>
</template>

<script>
import HomePageTop from "../../../../components/head/HomePageTop.vue";
import overallSatisfactionOfGoods from './components/overallSatisfactionOfGoods'
import overallServiceSatisfaction from './components/overallServiceSatisfaction'
import logisticsSatisfaction from './components/logisticsSatisfaction'

export default {
  components: {
    HomePageTop,
    overallSatisfactionOfGoods,
    overallServiceSatisfaction,
    logisticsSatisfaction
  },
  data() {
    return {
      porderEvaluate: '',
      fileList: [],
      disabled: false,
      imageDialogVisible: false,
      imgWidth: '',
      dialogImageUrl: '',
    }
  },
  mounted() {
    this.$api.storageDeliverDetail({
      porder_sn: this.$route.query.porder_sn
    }).then((res) => {
      if (res.data.porder_status_name === '已评价' || res.data.porder_status_name === '已签收') {
        if (res.data.porder_status_name === '已评价') {
          this.$api.getEvaluateDetail({
            porder_sn: this.$route.query.porder_sn
          }).then((res) => {
            this.disabled = true;
            this.$refs.overallSatisfactionOfGoods.disabled = true;
            this.$refs.overallServiceSatisfaction.disabled = true;
            this.$refs.logisticsSatisfaction.disabled = true;
            this.porderEvaluate = res.data[0].porder_desc; //配送单整体评价
            this.fileList = res.data[0].porder_images;//配送单整体评价图片
            this.$refs.overallSatisfactionOfGoods.overallSatisfactionOfGoodsValue = res.data[0].goods_whole_score;//商品整体满意度评分
            this.$refs.overallSatisfactionOfGoods.goods_pack_score = res.data[0].goods_pack_score;//商品包装是否满意
            if (res.data[0].goods_pack_score <= 2) {
              this.$refs.overallSatisfactionOfGoods.goods_pack_score_desc_status = true
            }
            this.$refs.overallSatisfactionOfGoods.goods_pack_score_desc = res.data[0].goods_pack_score_desc;//商品包装是否满意问题描述
            this.$refs.overallSatisfactionOfGoods.goods_pack_score_images = res.data[0].goods_pack_score_images; //商品包装是否满意问题图片
            this.$refs.overallSatisfactionOfGoods.goods_quality_score = res.data[0].goods_quality_score;//商品质量是否符合您的预期
            if (res.data[0].goods_quality_score <= 2) {
              this.$refs.overallSatisfactionOfGoods.goods_quality_score_desc_status = true
            }
            this.$refs.overallSatisfactionOfGoods.goods_quality_score_desc = res.data[0].goods_quality_score_desc; //商品质量是否符合您的预期问题描述
            this.$refs.overallSatisfactionOfGoods.goods_quality_score_images = res.data[0].goods_quality_score_images;//商品质量是否符合您的预期问题图片
            this.$refs.overallSatisfactionOfGoods.goods_complete_score = res.data[0].goods_complete_score;//商品是否完整（有无缺失，破损）
            if (res.data[0].goods_complete_score <= 2) {
              this.$refs.overallSatisfactionOfGoods.goods_complete_desc_status = true
            }
            this.$refs.overallSatisfactionOfGoods.goods_complete_score_desc = res.data[0].goods_complete_score_desc; //商品是否完整（有无缺失，破损）问题描述
            this.$refs.overallSatisfactionOfGoods.goods_complete_score_images = res.data[0].goods_complete_score_images; //商品是否完整（有无缺失，破损）问题图片
            this.$refs.overallServiceSatisfaction.service_whole_score = res.data[0].service_whole_score; //服务整体满意度
            this.$refs.overallServiceSatisfaction.service_process_score = res.data[0].service_process_score; //服务流程是否满意
            if (res.data[0].service_process_score <= 2) {
              this.$refs.overallServiceSatisfaction.service_process_status = true
            }
            this.$refs.overallServiceSatisfaction.service_process_score_desc = res.data[0].service_process_score_desc; //服务流程是否满意问题描述
            this.$refs.overallServiceSatisfaction.service_process_score_images = res.data[0].service_process_score_images; //服务流程是否满意问题图片
            this.$refs.overallServiceSatisfaction.service_admin_score = res.data[0].service_admin_score //服务人员是否能精准到位满足您的需求
            if (res.data[0].service_admin_score <= 2) {
              this.$refs.overallServiceSatisfaction.service_admin_status = true
            }
            this.$refs.overallServiceSatisfaction.service_admin_score_desc = res.data[0].service_admin_score_desc; //服务人员是否能精准到位满足您的需求问题描述
            this.$refs.overallServiceSatisfaction.service_admin_score_images = res.data[0].service_admin_score_images; //服务人员是否能精准到位满足您的需求问题图片
            this.$refs.overallServiceSatisfaction.service_admin_attitude_score = res.data[0].service_admin_attitude_score; //服务人员是否态度良好
            if (res.data[0].service_admin_attitude_score <= 2) {
              this.$refs.overallServiceSatisfaction.service_admin_attitude_status = true
            }
            this.$refs.overallServiceSatisfaction.service_admin_attitude_score_desc = res.data[0].service_admin_attitude_score_desc; //服务人员是否态度良好问题描述
            this.$refs.overallServiceSatisfaction.service_admin_attitude_score_images = res.data[0].service_admin_attitude_score_images; //服务人员是否态度良好问题图片
            this.$refs.overallServiceSatisfaction.service_admin_speed_score = res.data[0].service_admin_speed_score;//服务人员是否处理问题及时
            if (res.data[0].service_admin_speed_score <= 2) {
              this.$refs.overallServiceSatisfaction.service_admin_speed_status = true
            }
            this.$refs.overallServiceSatisfaction.service_admin_speed_score_desc = res.data[0].service_admin_speed_score_desc; //服务人员是否处理问题及时问题描述
            this.$refs.overallServiceSatisfaction.service_admin_speed_score_images = res.data[0].service_admin_speed_score_images; //服务人员是否处理问题及时问题图片
            this.$refs.logisticsSatisfaction.logistics_whole_score = res.data[0].logistics_whole_score; //物流整体满意度
            this.$refs.logisticsSatisfaction.logistics_speed_score = res.data[0].logistics_speed_score; //物流速度是否满意
            if (res.data[0].logistics_speed_score <= 2) {
              this.$refs.logisticsSatisfaction.logistics_speed_status = true
            }
            this.$refs.logisticsSatisfaction.logistics_speed_score_desc = res.data[0].logistics_speed_score_desc; //物流速度是否满意问题描述
            this.$refs.logisticsSatisfaction.logistics_speed_score_images = res.data[0].logistics_speed_score_images; //物流速度是否满意问题图片
            this.$refs.logisticsSatisfaction.logistics_fee_score = res.data[0].logistics_fee_score //物流费用是否满意
            if (res.data[0].logistics_fee_score <= 2) {
              this.$refs.logisticsSatisfaction.logistics_fee_status = true
            }
            this.$refs.logisticsSatisfaction.logistics_fee_score_desc = res.data[0].logistics_fee_score_desc;//物流费用是否满意问题描述
            this.$refs.logisticsSatisfaction.logistics_fee_score_images = res.data[0].logistics_fee_score_images; //物流费用是否满意问题图片
            this.$refs.logisticsSatisfaction.logistics_pass_score = res.data[0].logistics_pass_score;//物流服务（通关，配送等）是否满意
            if (res.data[0].logistics_pass_score <= 2) {
              this.$refs.logisticsSatisfaction.logistics_pass_status = true
            }
            this.$refs.logisticsSatisfaction.logistics_pass_score_desc = res.data[0].logistics_pass_score_desc;//物流服务（通关，配送等）是否满意问题描述
            this.$refs.logisticsSatisfaction.logistics_pass_score_images = res.data[0].logistics_pass_score_images;//物流服务（通关，配送等）是否满意问题图片,
          })
        }
      } else {
        window.open("about:blank", "_top").close();
      }
    })
  },
  methods: {
    //文件超出限定上传数量
    handleExceed(files, fileList) {
      this.$message.warning(`当前限制选择 4个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length} 个文件`);
    },
    //文件删除
    handleRemove(val) {
      const index = this.fileList.findIndex(item => item.url === val.url)
      this.fileList.splice(index, 1)
    },
    //文件上传成功
    handleSuccess(res, file) {
      this.fileList.push(res.data)
    },
    //提交配送单评价
    submitPorderEvaluation() {
      let val1 = this.$refs.overallSatisfactionOfGoods.isLegalEvaluation();//商品整体满意度
      let val2 = this.$refs.overallServiceSatisfaction.isLegalEvaluation();//整体服务满意度
      let val3 = this.$refs.logisticsSatisfaction.isLegalEvaluation();//物流满意度
      if (val1 === true && val2 === true && val3 === true) {
        let data = {
          porder_sn: this.$route.query.porder_sn,//配送单号
          goods_whole_score: this.$refs.overallSatisfactionOfGoods.overallSatisfactionOfGoodsValue,//商品整体满意度评分
          goods_pack_score: this.$refs.overallSatisfactionOfGoods.goods_pack_score,//商品包装是否满意
          goods_pack_score_desc: this.$refs.overallSatisfactionOfGoods.goods_pack_score_desc,//商品包装是否满意问题描述
          goods_pack_score_images: this.$refs.overallSatisfactionOfGoods.goods_pack_score_images,//商品包装是否满意问题图片
          goods_quality_score: this.$refs.overallSatisfactionOfGoods.goods_quality_score,//商品质量是否符合您的预期
          goods_quality_score_desc: this.$refs.overallSatisfactionOfGoods.goods_quality_score_desc, //商品质量是否符合您的预期问题描述
          goods_quality_score_images: this.$refs.overallSatisfactionOfGoods.goods_quality_score_images,//商品质量是否符合您的预期问题图片
          goods_complete_score: this.$refs.overallSatisfactionOfGoods.goods_complete_score,//商品是否完整（有无缺失，破损）
          goods_complete_score_desc: this.$refs.overallSatisfactionOfGoods.goods_complete_score_desc, //商品是否完整（有无缺失，破损）问题描述
          goods_complete_score_images: this.$refs.overallSatisfactionOfGoods.goods_complete_score_images, //商品是否完整（有无缺失，破损）问题图片
          service_whole_score: this.$refs.overallServiceSatisfaction.service_whole_score, //服务整体满意度
          service_process_score: this.$refs.overallServiceSatisfaction.service_process_score, //服务流程是否满意
          service_process_score_desc: this.$refs.overallServiceSatisfaction.service_process_score_desc, //服务流程是否满意问题描述
          service_process_score_images: this.$refs.overallServiceSatisfaction.service_process_score_images, //服务流程是否满意问题图片
          service_admin_score: this.$refs.overallServiceSatisfaction.service_admin_score, //服务人员是否能精准到位满足您的需求
          service_admin_score_desc: this.$refs.overallServiceSatisfaction.service_admin_score_desc, //服务人员是否能精准到位满足您的需求问题描述
          service_admin_score_images: this.$refs.overallServiceSatisfaction.service_admin_score_images, //服务人员是否能精准到位满足您的需求问题图片
          service_admin_attitude_score: this.$refs.overallServiceSatisfaction.service_admin_attitude_score, //服务人员是否态度良好
          service_admin_attitude_score_desc: this.$refs.overallServiceSatisfaction.service_admin_attitude_score_desc, //服务人员是否态度良好问题描述
          service_admin_attitude_score_images: this.$refs.overallServiceSatisfaction.service_admin_attitude_score_images, //服务人员是否态度良好问题图片
          service_admin_speed_score: this.$refs.overallServiceSatisfaction.service_admin_speed_score,//服务人员是否处理问题及时
          service_admin_speed_score_desc: this.$refs.overallServiceSatisfaction.service_admin_speed_score_desc, //服务人员是否处理问题及时问题描述
          service_admin_speed_score_images: this.$refs.overallServiceSatisfaction.service_admin_speed_score_images, //服务人员是否处理问题及时问题图片
          logistics_whole_score: this.$refs.logisticsSatisfaction.logistics_whole_score, //物流整体满意度
          logistics_speed_score: this.$refs.logisticsSatisfaction.logistics_speed_score, //物流速度是否满意
          logistics_speed_score_desc: this.$refs.logisticsSatisfaction.logistics_speed_score_desc, //物流速度是否满意问题描述
          logistics_speed_score_images: this.$refs.logisticsSatisfaction.logistics_speed_score_images, //物流速度是否满意问题图片
          logistics_fee_score: this.$refs.logisticsSatisfaction.logistics_fee_score, //物流费用是否满意
          logistics_fee_score_desc: this.$refs.logisticsSatisfaction.logistics_fee_score_desc,//物流费用是否满意问题描述
          logistics_fee_score_images: this.$refs.logisticsSatisfaction.logistics_fee_score_images, //物流费用是否满意问题图片
          logistics_pass_score: this.$refs.logisticsSatisfaction.logistics_pass_score,//物流服务（通关，配送等）是否满意
          logistics_pass_score_desc: this.$refs.logisticsSatisfaction.logistics_pass_score_desc, //物流服务（通关，配送等）是否满意问题描述
          logistics_pass_score_images: this.$refs.logisticsSatisfaction.logistics_pass_score_images, //物流服务（通关，配送等）是否满意问题图片,
          porder_desc: this.porderEvaluate, //配送单整体评价
          porder_images: this.fileList, //配送单整体评价图片
        };
        this.$api.porderEvaluate(data).then((res) => {
          if (res.code != 0) return this.$message.error(this.$fanyi(res.msg));
          this.$message.success(this.$fanyi("服务评价提交成功！"));
          setTimeout(() => {
            window.open("about:blank", "_top").close();
          }, 1500)
        });
      } else {
        this.$message.error(this.$fanyi('请填写完评价内容再进行提交'));
      }
    },
    //上传图片规则检验
    beforeAvatarUpload(file) {
      const isJPG = file.type === 'image/jpeg' || 'image/png' || 'image/pneg' || 'image/jpg';
      const isLt2M = file.size / 1024 / 1024 < 10;
      if (!isJPG) {
        this.$message.error(this.$fanyi('图片格式不正确！'));
      }
      if (!isLt2M) {
        this.$message.error(this.$fanyi('上传的图片大小不能超过 10MB!'));
      }
      return isJPG && isLt2M;
    },
    //图片预览
    handlePictureCardPreview(file) {
      this.imageDialogVisible = true;
      this.dialogImageUrl = file.response.data;
    },
    pictureCardPreview(item) {
      this.imageDialogVisible = true;
      this.dialogImageUrl = item;
    }
  }
}
</script>

<style lang="scss" scoped>
.serviceEvaluationPage {
  background: #fff;
  margin: 50px auto 0;
  width: 1400px;
  border-radius: 10px;

  header {
    background: #FFFFFF;
    height: 73px;
    justify-content: space-between;
    padding: 0 26px;
    line-height: 73px;
    border-bottom: 1px solid #DFDFDF;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }

  section {
    padding: 30px 150px 60px;

    .sectionTitle {
      color: #333;
      font-weight: bold;
      font-size: 20px;
      text-align: center;
    }

    .sectionTitleExplain {
      color: #B4272B;
      font-size: 12px;
      margin: 15px 0 29px;
      text-align: center;
    }

    .headerEvaluateContainer {
      border: 1px solid #DFDFDF;
      padding: 60px 0;
      display: flex;
      flex-direction: column;
      align-items: center;

      /deep/ .el-textarea__inner {
        width: 510px;
        height: 150px;
        background: #FFFFFF;
        border: 1px solid #DFDFDF;
        border-radius: 3px;
      }

      .picUploadContainer {
        width: 510px;
        margin-top: 28px;

        .picUploadHeaderContainer {
          margin-bottom: 12px;

          div:first-child {
            color: #333333;
          }

          div:last-child {
            color: #B4272B;
            font-size: 12px;
          }
        }

        /deep/ .el-upload-list__item {
          width: 100px;
          height: 100px;
          line-height: 100px;
          margin-right: 17px;
        }

        /deep/ .el-upload-list__item:nth-child(4n) {
          margin-right: 0;
        }

        /deep/ .el-upload--picture-card {
          width: 100px;
          height: 100px;
          line-height: 100px;
          background: #FFFFFF;
          border: 1px solid #DFDFDF;
          border-radius: 3px;
        }

      }
    }

    .evaluateTitle {
      color: #000000;
      font-weight: bold;
      font-size: 18px;
      margin-bottom: 20px;
      text-align: center;
    }
  }

  .imageList {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;

    img {
      width: 100px;
      cursor: pointer;
    }
  }

  footer {
    display: flex;
    justify-content: center;
    //margin-top: 60px;
    padding-bottom: 60px;

    div {
      width: 510px;
      height: 46px;
      background: #B4272B;
      border-radius: 8px;
      text-align: center;
      line-height: 46px;
      font-size: 18px;
      cursor: pointer;
      color: #fff;
    }
  }
}
</style>
