<template>
  <div style="border-bottom: 1px solid #DFDFDF;margin-top:60px;width: 900px;">
    <div class="evaluateTitle">{{ $fanyi("整体服务满意度") }}</div>
    <div class="headerRateContainer">
      <el-rate v-model="service_whole_score" :disabled="disabled"></el-rate>
    </div>
    <div class="flex footerRateContainer">
      <div class="text">{{ $fanyi("服务流程是否满意") }}</div>
      <el-rate v-model="service_process_score" :disabled="disabled" @change="service_process_score_change"></el-rate>
    </div>
    <div v-show="service_process_status===true" class="footerRateContainer">
      <el-input v-model="service_process_score_desc" :readonly="disabled" resize="none" type="textarea"/>
      <div class="picUploadContainer">
        <div class="picUploadHeaderContainer flexAndCenter">
          <div>{{ $fanyi("图片上传") }}</div>
          <div>{{ $fanyi("（*单张图片不能超过10MB，最多上传4个图片）") }}</div>
        </div>
        <el-upload
            :action="uploadUrl"
            list-type="picture-card"
            :file-list="service_process_score_images"
            :limit="4"
            v-if="disabled===false"
            :before-upload="beforeAvatarUpload"
            :on-exceed="serviceProcessScoreHandleExceed"
            :on-preview="handlePictureCardPreview"
            :on-success="serviceProcessScoreHandleSuccess"
            :on-remove="serviceProcessScoreHandleRemove">
          <i class="el-icon-plus"></i>
        </el-upload>
        <div v-else class="imageList">
          <img v-for="(item,index) in service_admin_score_images" :key="index" :src="item"
               @click="pictureCardPreview(item)">
        </div>
      </div>
    </div>
    <div class="flex footerRateContainer">
      <div class="text">{{ $fanyi("服务人员是否能精准到位满足您的需求") }}</div>
      <el-rate v-model="service_admin_score" :disabled="disabled" @change="service_admin_score_change"></el-rate>
    </div>
    <div v-show="service_admin_status===true" class="footerRateContainer">
      <el-input v-model="service_admin_score_desc" :readonly="disabled" resize="none" type="textarea"/>
      <div class="picUploadContainer">
        <div class="picUploadHeaderContainer flexAndCenter">
          <div>{{ $fanyi("图片上传") }}</div>
          <div>{{ $fanyi("（*单张图片不能超过10MB，最多上传4个图片）") }}</div>
        </div>
        <el-upload
            :action="uploadUrl"
            list-type="picture-card"
            :file-list="service_admin_score_images"
            :limit="4"
            v-if="disabled===false"
            :before-upload="beforeAvatarUpload"
            :on-exceed="serviceAdminScoreHandleExceed"
            :on-preview="handlePictureCardPreview"
            :on-success="serviceAdminScoreHandleSuccess"
            :on-remove="serviceAdminScoreHandleRemove">
          <i class="el-icon-plus"></i>
        </el-upload>
        <div v-else class="imageList">
          <img v-for="(item,index) in service_admin_score_images" :key="index" :src="item"
               @click="pictureCardPreview(item)">
        </div>
      </div>
    </div>
    <div class="flex footerRateContainer">
      <div class="text">{{ $fanyi("服务人员是否态度良好") }}</div>
      <el-rate v-model="service_admin_attitude_score" :disabled="disabled"
               @change="service_admin_attitude_score_change"></el-rate>
    </div>
    <div v-show="service_admin_attitude_status===true" class="footerRateContainer">
      <el-input v-model="service_admin_attitude_score_desc" :readonly="disabled" resize="none" type="textarea"/>
      <div class="picUploadContainer">
        <div class="picUploadHeaderContainer flexAndCenter">
          <div>{{ $fanyi("图片上传") }}</div>
          <div>{{ $fanyi("（*单张图片不能超过10MB，最多上传4个图片）") }}</div>
        </div>
        <el-upload
            :action="uploadUrl"
            list-type="picture-card"
            :file-list="service_admin_attitude_score_images"
            :limit="4"
            v-if="disabled===false"
            :before-upload="beforeAvatarUpload"
            :on-exceed="serviceAdminAttitudeScoreHandleExceed"
            :on-preview="handlePictureCardPreview"
            :on-success="serviceAdminAttitudeScoreHandleSuccess"
            :on-remove="serviceAdminAttitudeScoreHandleRemove">
          <i class="el-icon-plus"></i>
        </el-upload>
        <div v-else class="imageList">
          <img v-for="(item,index) in service_admin_attitude_score_images" :key="index" :src="item"
               @click="pictureCardPreview(item)">
        </div>
      </div>
    </div>
    <div class="flex footerRateContainer">
      <div class="text">{{ $fanyi("服务人员是否处理问题及时") }}</div>
      <el-rate v-model="service_admin_speed_score" :disabled="disabled"
               @change="service_admin_speed_score_change"></el-rate>
    </div>
    <div v-show="service_admin_speed_status===true" class="footerRateContainer">
      <el-input v-model="service_admin_speed_score_desc" :readonly="disabled" resize="none" type="textarea"/>
      <div class="picUploadContainer">
        <div class="picUploadHeaderContainer flexAndCenter">
          <div>{{ $fanyi("图片上传") }}</div>
          <div>{{ $fanyi("（*单张图片不能超过10MB，最多上传4个图片）") }}</div>
        </div>
        <el-upload
            :action="uploadUrl"
            list-type="picture-card"
            :file-list="service_admin_speed_score_images"
            :limit="4"
            v-if="disabled===false"
            :before-upload="beforeAvatarUpload"
            :on-exceed="serviceAdminSpeedScoreHandleExceed"
            :on-preview="handlePictureCardPreview"
            :on-success="serviceAdminSpeedScoreHandleSuccess"
            :on-remove="serviceAdminSpeedScoreHandleRemove">
          <i class="el-icon-plus"></i>
        </el-upload>
        <div v-else class="imageList">
          <img v-for="(item,index) in service_admin_speed_score_images" :key="index" :src="item"
               @click="pictureCardPreview(item)">
        </div>
      </div>
    </div>
    <el-dialog :visible.sync="imageDialogVisible" width="800px">
      <div style="display: flex;align-items: center;justify-content: center">
        <img :src="dialogImageUrl" alt="" width="600px">
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      disabled: false,
      service_whole_score: null,//整体服务满意度
      service_process_score: null,//服务流程是否满意
      service_process_score_desc: '',//服务流程是否满意问题描述
      service_process_score_images: [],//服务流程是否满意问题图片
      service_process_status: false,//服务流程是否满意是否展示评价内容
      service_admin_score: null,//服务人员是否能精准到位满足您的需求
      service_admin_score_desc: '',//服务人员是否能精准到位满足您的需求问题描述
      service_admin_score_images: [],//服务人员是否能精准到位满足您的需求问题图片
      service_admin_status: false,//服务人员是否能精准到位满足您的需求是否展示评价内容
      service_admin_attitude_score: null,//服务人员是否态度良好
      service_admin_attitude_score_desc: '',//服务人员是否态度良好问题描述
      service_admin_attitude_score_images: [],//服务人员是否态度良好问题图片
      service_admin_attitude_status: false,//服务人员是否态度良好是否展示评价内容
      service_admin_speed_score: null,//服务人员是否处理问题及时
      service_admin_speed_score_desc: '',//服务人员是否处理问题及时问题描述
      service_admin_speed_score_images: [],//服务人员是否处理问题及时问题图片
      service_admin_speed_status: false,//服务人员是否处理问题及时是否展示评价内容
      imageDialogVisible: false,
      imgWidth: '',
      dialogImageUrl: '',
    }
  },
  methods: {
    //服务流程是否满意评价上传文件超出限制
    serviceProcessScoreHandleExceed(files, fileList) {
      this.$message.warning(`当前限制选择 4个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length} 个文件`);
    },
    //服务人员是否能精准到位满足您的需求评价上传文件超出限制
    serviceAdminScoreHandleExceed(files, fileList) {
      this.$message.warning(`当前限制选择 4个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length} 个文件`);
    },
    //服务人员是否态度良好评价上传文件超出限制
    serviceAdminAttitudeScoreHandleExceed(files, fileList) {
      this.$message.warning(`当前限制选择 4个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length} 个文件`);
    },
    //服务人员是否处理问题及时评价上传文件超出限制
    serviceAdminSpeedScoreHandleExceed(files, fileList) {
      this.$message.warning(`当前限制选择 4个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length} 个文件`);
    },
    //服务流程是否满意评价上传文件成功
    serviceProcessScoreHandleSuccess(res, file) {
      this.service_process_score_images.push(file)
    },
    //服务人员是否能精准到位满足您的需求评价上传文件成功
    serviceAdminScoreHandleSuccess(res, file) {
      this.service_admin_score_images.push(res.data)
    },
    //服务人员是否态度良好评价上传文件成功
    serviceAdminAttitudeScoreHandleSuccess(res, file) {
      this.service_admin_attitude_score_images.push(res.data)
    },
    //服务人员是否处理问题及时评价上传文件成功
    serviceAdminSpeedScoreHandleSuccess(res, file) {
      this.service_admin_speed_score_images.push(res.data)
    },
    //服务流程是否满意评价删除文件
    serviceProcessScoreHandleRemove(val) {
      const index = this.service_process_score_images.findIndex(item => item.url === val.url)
      this.service_process_score_images.splice(index, 1)
    },
    //服务人员是否能精准到位满足您的需求评价删除文件
    serviceAdminScoreHandleRemove(val) {
      const index = this.service_admin_score_images.findIndex(item => item.url === val.url)
      this.service_admin_score_images.splice(index, 1)
    },
    //服务人员是否态度良好评价删除文件
    serviceAdminAttitudeScoreHandleRemove(val) {
      const index = this.service_admin_attitude_score_images.findIndex(item => item.url === val.url)
      this.service_admin_attitude_score_images.splice(index, 1)
    },
    //服务人员是否处理问题及时评价删除文件
    serviceAdminSpeedScoreHandleRemove(val) {
      const index = this.service_admin_speed_score_images.findIndex(item => item.url === val.url)
      this.service_admin_speed_score_images.splice(index, 1)
    },
    //更新服务流程是否满意的值
    service_process_score_change(e) {
      this.service_process_score = e;
      e <= 2 ? this.service_process_status = true : this.service_process_status = false;
      this.$forceUpdate();
    },
    //更新服务人员是否能精准到位满足您的需求的值
    service_admin_score_change(e) {
      this.service_admin_score = e;
      e <= 2 ? this.service_admin_status = true : this.service_admin_status = false;
      this.$forceUpdate();
    },
    //更新服务人员是否态度良好的值
    service_admin_attitude_score_change(e) {
      this.service_admin_attitude_score = e;
      e <= 2 ? this.service_admin_attitude_status = true : this.service_admin_attitude_status = false;
      this.$forceUpdate();
    },
    //更新服务人员是否处理问题及时的值
    service_admin_speed_score_change(e) {
      this.service_admin_speed_score = e;
      e <= 2 ? this.service_admin_speed_status = true : this.service_admin_speed_status = false;
      this.$forceUpdate();
    },
    //上传图片规则检验
    beforeAvatarUpload(file) {
      const isJPG = file.type === 'image/jpeg' || 'image/png' || 'image/pneg' || 'image/jpg';
      const isLt2M = file.size / 1024 / 1024 < 10;
      if (!isJPG) {
        this.$message.error(this.$fanyi('图片格式不正确！'));
      }
      if (!isLt2M) {
        this.$message.error(this.$fanyi('上传的图片大小不能超过 10MB!'));
      }
      return isJPG && isLt2M;
    },
    //判断用户输入的评价内容是否完整
    isLegalEvaluation() {
      let val = '';
      if (this.service_whole_score != null &&
          //服务流程是否满意
          (this.service_process_score != null && (this.service_process_score > 2 || (this.service_process_score <= 2 && this.service_process_score_desc !== ''))) &&
          //服务人员是否能精准到位满足您的需求
          (this.service_admin_score != null && (this.service_admin_score > 2 || (this.service_admin_score <= 2 && this.service_admin_score_desc !== ''))) &&
          //服务人员是否态度良好
          (this.service_admin_attitude_score != null && (this.service_admin_attitude_score > 2 || (this.service_admin_attitude_score <= 2 && this.service_admin_attitude_score_desc !== ''))) &&
          //服务人员是否处理问题及时
          (this.service_admin_speed_score != null && (this.service_admin_speed_score > 2 || (this.service_admin_speed_score <= 2 && this.service_admin_speed_score_desc !== '')))) {
        val = true
      } else {
        val = false
      }
      return val
    },
    //图片预览
    handlePictureCardPreview(file) {
      this.imageDialogVisible = true;
      this.dialogImageUrl = file.response.data;
    },
    pictureCardPreview(item) {
      this.imageDialogVisible = true;
      this.dialogImageUrl = item;
    }
  }
}
</script>

<style lang="scss" scoped>
.evaluateTitle {
  color: #000000;
  font-weight: bold;
  font-size: 18px;
  margin-bottom: 20px;
  text-align: center;
}

.headerRateContainer {
  margin-bottom: 64px;
  display: flex;
  justify-content: center;

  /deep/ .el-rate__icon, /deep/ .el-icon-star-off, /deep/ .el-icon-star-on {
    font-size: 34px;
  }
}

.picUploadContainer {
  width: 510px;
  margin-top: 28px;

  .picUploadHeaderContainer {
    margin-bottom: 12px;

    div:first-child {
      color: #333333;
    }

    div:last-child {
      color: #B4272B;
      font-size: 12px;
    }
  }

  /deep/ .el-upload-list__item {
    width: 100px;
    height: 100px;
    line-height: 100px;
    margin-right: 17px;
  }

  /deep/ .el-upload-list__item:nth-child(4n) {
    margin-right: 0;
  }

  /deep/ .el-upload--picture-card {
    width: 100px;
    height: 100px;
    line-height: 100px;
    background: #FFFFFF;
    border: 1px solid #DFDFDF;
    border-radius: 3px;
  }

  .imageList {
    display: flex;
    justify-content: space-between;
    align-items: center;

    img {
      width: 100px;
      cursor: pointer;
    }
  }
}

.footerRateContainer {
  justify-content: space-between;
  width: 100%;
  padding: 0 95px;
  margin-bottom: 26px;

  .text {
    margin-top: 3px;
  }

  /deep/ .el-rate__icon, /deep/ .el-icon-star-off {
    font-size: 26px;
  }

  /deep/ .el-textarea__inner {
    width: 710px !important;
    height: 150px;
    background: #FFFFFF;
    border: 1px solid #DFDFDF;
    border-radius: 3px;
  }
}
</style>
