<template>
  <div style="border-bottom: 1px solid #DFDFDF;width: 900px;">
    <div class="evaluateTitle">{{ $fanyi("商品整体满意度") }}</div>
    <div class="headerRateContainer">
      <el-rate v-model="overallSatisfactionOfGoodsValue" :disabled="disabled"></el-rate>
    </div>
    <div class="flex footerRateContainer">
      <div class="text">{{ $fanyi("商品包装是否满意") }}</div>
      <el-rate v-model="goods_pack_score" :disabled="disabled" @change="goods_pack_score_change"></el-rate>
    </div>
    <div v-show="goods_pack_score_desc_status===true" class="footerRateContainer">
      <el-input v-model="goods_pack_score_desc" :readonly="disabled" resize="none" type="textarea"/>
      <div class="picUploadContainer">
        <div class="picUploadHeaderContainer flexAndCenter">
          <div>{{ $fanyi("图片上传") }}</div>
          <div>{{ $fanyi("（*单张图片不能超过10MB，最多上传4个图片）") }}</div>
        </div>
        <el-upload
            :action="uploadUrl"
            list-type="picture-card"
            :file-list="goods_pack_score_images"
            :limit="4"
            v-if="disabled===false"
            :before-upload="beforeAvatarUpload"
            :on-exceed="goodsPackScoreHandleExceed"
            :on-success="goodsPackScoreHandleSuccess"
            :on-preview="handlePictureCardPreview"
            :on-remove="goodsPackScoreHandleRemove">
          <i class="el-icon-plus"></i>
        </el-upload>
        <div v-else class="imageList">
          <img v-for="(item,index) in goods_pack_score_images" :key="index" :src="item"
               @click="pictureCardPreview(item)"/>
        </div>
      </div>
    </div>
    <div class="flex footerRateContainer">
      <div class="text">{{ $fanyi("商品质量是否符合您的预期") }}</div>
      <el-rate v-model="goods_quality_score" :disabled="disabled" @change="goods_quality_score_change"></el-rate>
    </div>
    <div v-show="goods_quality_score_desc_status===true" class="footerRateContainer">
      <el-input v-model="goods_quality_score_desc" :readonly="disabled" resize="none" type="textarea"/>
      <div class="picUploadContainer">
        <div class="picUploadHeaderContainer flexAndCenter">
          <div>{{ $fanyi("图片上传") }}</div>
          <div>{{ $fanyi("（*单张图片不能超过10MB，最多上传4个图片）") }}</div>
        </div>
        <el-upload
            :action="uploadUrl"
            list-type="picture-card"
            :file-list="goods_quality_score_images"
            :limit="4"
            v-if="disabled===false"
            :before-upload="beforeAvatarUpload"
            :on-exceed="goodsQualityScoreHandleExceed"
            :on-success="goodsQualityScoreHandleSuccess"
            :on-preview="handlePictureCardPreview"
            :on-remove="goodsQualityScoreHandleRemove">
          <i class="el-icon-plus"></i>
        </el-upload>
        <div v-else class="imageList">
          <img v-for="(item,index) in goods_quality_score_images" :key="index" :src="item"
               @click="pictureCardPreview(item)">
        </div>
      </div>
    </div>
    <div class="flex footerRateContainer">
      <div class="text">{{ $fanyi("商品是否完整（有无缺失、破损）") }}</div>
      <el-rate v-model="goods_complete_score" :disabled="disabled" @change="goods_complete_score_change"></el-rate>
    </div>
    <div v-show="goods_complete_desc_status===true" class="footerRateContainer">
      <el-input v-model="goods_complete_score_desc" :readonly="disabled" resize="none" type="textarea"/>
      <div class="picUploadContainer">
        <div class="picUploadHeaderContainer flexAndCenter">
          <div>{{ $fanyi("图片上传") }}</div>
          <div>{{ $fanyi("（*单张图片不能超过10MB，最多上传4个图片）") }}</div>
        </div>
        <el-upload
            :action="uploadUrl"
            list-type="picture-card"
            :file-list="goods_complete_score_images"
            :limit="4"
            v-if="disabled===false"
            :before-upload="beforeAvatarUpload"
            :on-exceed="goodsCompleteScoreHandleExceed"
            :on-success="goodsCompleteScoreHandleSuccess"
            :on-preview="handlePictureCardPreview"
            :on-remove="goodsCompleteScoreHandleRemove">
          <i class="el-icon-plus"></i>
        </el-upload>
        <div v-else class="imageList">
          <img v-for="(item,index) in goods_complete_score_images" :key="index" :src="item"
               @click="pictureCardPreview(item)">
        </div>
      </div>
    </div>
    <el-dialog :visible.sync="imageDialogVisible" width="800px">
      <div style="display: flex;align-items: center;justify-content: center">
        <img :src="dialogImageUrl" alt="" width="600px">
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      disabled: false,
      goods_pack_score: null,//商品包装是否满意
      goods_pack_score_desc: '',//商品包装是否满意问题描述
      goods_pack_score_images: [],//商品包装是否满意问题图片
      goods_pack_score_desc_status: false,//商品包装是否满意问题是否展示评价内容
      goods_quality_score: null,//商品质量是否符合您的预期
      goods_quality_score_desc: '',//商品质量是否符合您的预期问题描述
      goods_quality_score_images: [],//商品质量是否符合您的预期问题图片
      goods_quality_score_desc_status: false,//商品质量是否符合您的预期问题是否展示评价内容
      goods_complete_score: null,//商品是否完整（有无缺失、破损）
      goods_complete_score_desc: '',//商品是否完整（有无缺失，破损）问题描述
      goods_complete_score_images: [],//商品是否完整（有无缺失，破损）问题图片
      goods_complete_desc_status: false,//商品是否完整（有无缺失，破损）问题是否展示评价内容
      overallSatisfactionOfGoodsValue: null,//商品整体满意度评分
      imageDialogVisible: false,
      imgWidth: '',
      dialogImageUrl: '',
    }
  },
  methods: {
    //商品包装是否满意评价上传文件超出限制
    goodsPackScoreHandleExceed(files, fileList) {
      this.$message.warning(`当前限制选择 4个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length} 个文件`);
    },
    //商品质量是否符合您的预期评价上传文件超出限制
    goodsQualityScoreHandleExceed(files, fileList) {
      this.$message.warning(`当前限制选择 4个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length} 个文件`);
    },
    //商品是否完整（有无缺失，破损）评价上传文件超出限制
    goodsCompleteScoreHandleExceed(files, fileList) {
      this.$message.warning(`当前限制选择 4个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length} 个文件`);
    },
    //商品包装是否满意评价上传文件成功
    goodsPackScoreHandleSuccess(res, file) {
      this.goods_pack_score_images.push(res.data)
    },
    //商品质量是否符合您的预期评价上传文件成功
    goodsQualityScoreHandleSuccess(res, file) {
      this.goods_quality_score_images.push(res.data)
    },
    //商品是否完整（有无缺失，破损）评价上传文件成功
    goodsCompleteScoreHandleSuccess(res, file) {
      this.goods_complete_score_images.push(res.data)
    },
    //商品包装是否满意评价删除文件
    goodsPackScoreHandleRemove(val) {
      const index = this.goods_pack_score_images.findIndex(item => item.url === val.url)
      this.goods_pack_score_images.splice(index, 1)
    },
    //上传图片规则检验
    beforeAvatarUpload(file) {
      const isJPG = file.type === 'image/jpeg' || 'image/png' || 'image/pneg' || 'image/jpg';
      const isLt2M = file.size / 1024 / 1024 < 10;
      if (!isJPG) {
        this.$message.error(this.$fanyi('图片格式不正确！'));
      }
      if (!isLt2M) {
        this.$message.error(this.$fanyi('上传的图片大小不能超过 10MB!'));
      }
      return isJPG && isLt2M;
    },
    //商品质量是否符合您的预期评价删除文件
    goodsQualityScoreHandleRemove(val) {
      const index = this.goods_quality_score_images.findIndex(item => item.url === val.url)
      this.goods_quality_score_images.splice(index, 1)
    },
    //商品质量是否符合您的预期评价删除文件
    goodsCompleteScoreHandleRemove(val) {
      const index = this.goods_complete_score_images.findIndex(item => item.url === val.url)
      this.goods_complete_score_images.splice(index, 1)
    },
    //更新商品包装是否满意的值
    goods_pack_score_change(e) {
      this.goods_pack_score = e;
      e <= 2 ? this.goods_pack_score_desc_status = true : this.goods_pack_score_desc_status = false;
      this.$forceUpdate();
    },
    //更新商品质量是否符合您的预期的值
    goods_quality_score_change(e) {
      this.goods_quality_score = e;
      e <= 2 ? this.goods_quality_score_desc_status = true : this.goods_quality_score_desc_status = false;
      this.$forceUpdate();
    },
    //更新商品是否完整（有无缺失，破损）的值
    goods_complete_score_change(e) {
      this.goods_complete_score = e;
      e <= 2 ? this.goods_complete_desc_status = true : this.goods_complete_desc_status = false;
      this.$forceUpdate();
    },
    //判断用户输入的评价内容是否完整
    isLegalEvaluation() {
      let val = '';
      if (this.overallSatisfactionOfGoodsValue != null &&
          //商品包装是否满意
          (this.goods_pack_score != null && (this.goods_pack_score > 2 || (this.goods_pack_score <= 2 && this.goods_pack_score_desc !== ''))) &&
          //商品质量是否符合您的预期
          (this.goods_quality_score != null && (this.goods_quality_score > 2 || (this.goods_quality_score <= 2 && this.goods_quality_score_desc !== ''))) &&
          //商品是否完整（有无缺失，破损）
          (this.goods_complete_score != null && (this.goods_complete_score > 2 || (this.goods_complete_score <= 2 && this.goods_complete_score_desc !== '')))
      ) {
        val = true
      } else {
        val = false
      }
      return val
    },
    //图片预览
    handlePictureCardPreview(file) {
      this.imageDialogVisible = true;
      this.dialogImageUrl = file.response.data;
    },
    pictureCardPreview(item) {
      this.imageDialogVisible = true;
      this.dialogImageUrl = item;
    }
  }
}
</script>

<style lang="scss" scoped>
.evaluateTitle {
  color: #000000;
  font-weight: bold;
  font-size: 18px;
  margin-bottom: 20px;
  text-align: center;
}

.headerRateContainer {
  margin-bottom: 64px;
  display: flex;
  justify-content: center;

  /deep/ .el-rate__icon, /deep/ .el-icon-star-off {
    font-size: 34px;
  }
}

.picUploadContainer {
  width: 510px;
  margin-top: 28px;

  .picUploadHeaderContainer {
    margin-bottom: 12px;

    div:first-child {
      color: #333333;
    }

    div:last-child {
      color: #B4272B;
      font-size: 12px;
    }
  }

  /deep/ .el-upload-list__item {
    width: 100px;
    height: 100px;
    line-height: 100px;
    margin-right: 17px;
  }

  /deep/ .el-upload-list__item:nth-child(4n) {
    margin-right: 0;
  }

  /deep/ .el-upload--picture-card {
    width: 100px;
    height: 100px;
    line-height: 100px;
    background: #FFFFFF;
    border: 1px solid #DFDFDF;
    border-radius: 3px;
  }

  .imageList {
    display: flex;
    justify-content: space-between;
    align-items: center;

    img {
      width: 100px;
      cursor: pointer;
    }
  }
}

.footerRateContainer {
  justify-content: space-between;
  width: 100%;
  padding: 0 95px;
  margin-bottom: 26px;

  .text {
    margin-top: 3px;
  }

  /deep/ .el-rate__icon, /deep/ .el-icon-star-off, /deep/ .el-icon-star-off {
    font-size: 26px;
  }

  /deep/ .el-textarea__inner {
    width: 710px !important;
    height: 150px;
    background: #FFFFFF;
    border: 1px solid #DFDFDF;
    border-radius: 3px;
  }
}
</style>
